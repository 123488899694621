import * as React from "react";
import JewelleryProducts from "../../../components/jewelleryProducts";

const Categories = ({ section }) => {
  return (
    <div
      className={`flex flex-col container px-6 lg:pl-[86px] lg:pr-[91px] mx-auto pb-6 lg:pb-28 ${
        section?.text && "text-center pt-16"
      }`}
    >
      <p
        data-aos="fade-down"
        className="text-white py-4 uppercase text-[10px] md:text-xl lg:text-[16px]"
      >
        {section?.title}
      </p>

      <h1
        data-aos="fade-down"
        className={`text-white leading-[2rem] lg:leading-[5rem] carmorant-font text-4xl md:text-7xl lg:text-7xl font-semibold ${
          section?.text && "lg:text-7xl font-semibold leading-[3rem] "
        }`}
      >
        {section?.mainHeading}
      </h1>
      <div className="w-full py-8 ">
        <JewelleryProducts section={section} />
      </div>
    </div>
  );
};

export default Categories;
