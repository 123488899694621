import React from "react";
import { generateImageUrl } from "../../../client";

const HandpickedProducts = ({section}) => {

  return (
    <div className="bg-[#353530]">
      <div className="container px-6 lg:pl-[86px] lg:pr-[91px] mx-auto pt-12 pb-20">
        <div className="flex flex-col items-center" data-aos="fade-down">
          <p className="py-4 uppercase text-white text-[10px] md:text-xl lg:text-[15px]">
            {section?.title}
          </p>
          <p className="leading-[3rem] lg:leading-[5rem] carmorant-font text-4xl md:text-7xl lg:text-6xl font-semibold text-white">
            {section?.mainHeading}
          </p>
          <button className="flex items-center my-6">
            <p className="text-[#FCD462] mr-2 text-[14px] md:text-[22px] lg:text-[14px]">
              {section?.viewText}
            </p>
            <img
              className="w-[20px] pt-[3px]"
              alt="arrow"
              src={generateImageUrl(section?.viewImage?.asset?._ref)}
              loading="lazy"
            />
          </button>
        </div>
        <div className="flex justify-between flex-wrap">
          {section?.handPickedCard?.map((single, index) => (
            <div
              data-aos="zoom-in-up"
              className="w-full lg:w-[30%] cursor-pointer mt-12 boxhover"
              key={index}
            >
              <div
                className="handpicked-bg min-h-[245px] md:min-h-[530px] lg:min-h-[245px]"
                style={{
                  backgroundImage: `url(${
                    generateImageUrl(single?.mainImage?.asset?._ref) || ""
                  })`,
                  backgroundSize: `cover`,
                  backgroundRepeat: `no-repeat`,
                  backgroundPosition: `center`,
                  loading: `lazy`,
                }}
              ></div>
              <div
                key={index}
                className="text-white flex flex-col items-center pt-6 pb-8 bg-[#2F2E2D] px-6"
              >
                <img
                  className="w-[48px] md:w-[120px] lg:w-[48px]"
                  alt="productpic"
                  loading="lazy"
                  src={generateImageUrl(single?.image?.asset?._ref)}
                />
                <p className="carmorant-font text-3xl md:text-5xl lg:text-2xl xl:text-3xl font-semibold py-4">
                  {single.productName}
                </p>
                <p className="text-center text-[9px] md:text-[20px] lg:text-[9px] font-light uppercase leading-[20px] md:leading-[36px] lg:leading-[20px]">
                  {single.productDescription}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HandpickedProducts;
