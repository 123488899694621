import React from "react";
import Aarrow from "../../../assets/images/Arrow 8.svg";
import { generateImageUrl } from "../../../client";

const TrendingCollection = ({ section }) => {
  return (
    <div className="bg-[#353530]">
      <div className="container px-6 lg:pl-[86px] lg:pr-[91px] mx-auto">
        <div
          className="flex flex-col items-center py-[42px] text-white"
          data-aos="fade-down"
        >
          <p className="uppercase text-[10px] md:text-xl lg:text-[15px]">
            {section?.title}
          </p>
          <p className="py-6 lg:py-10 text-4xl md:text-7xl lg:text-5xl carmorant-font font-semibold">
            {section?.mainHeading}
          </p>
          <button className="flex items-center ">
            <p className="text-[#FCD462] mr-2 text-[14px] md:text-[22px] lg:text-[14px]">
              {section?.button?.viewMore}
            </p>
            <img className="w-[20px] pt-[3px]" src={Aarrow} alt="arrow" loading="lazy"/>
          </button>
        </div>
        <div className="trendingCards flex flex-wrap justify-between">
          {section?.trendingCollectionCards?.map((single, index) => (
            <div
              data-aos="zoom-in-up"
              className="w-[47%] lg:w-[23%] pb-8 cursor-pointer boxhover"
              key={index}
            >
              <img
                className=""
                src={generateImageUrl(single?.productImage?.asset?._ref)}
                alt="ring"
                loading="lazy"
              />

              <div className="text-center text-white py-6">
                <p className="carmorant-font text-xl md:text-4xl lg:text-2xl xl:text-3xl">
                  {" "}
                  {single?.productName}
                </p>
                <p className="text-[9px] md:text-[18px] lg:text-[14px] font-light pt-[4px] lg:pt-2 pb-[4px] lg:pb-3">
                  {single?.productSize}
                </p>
                <p className="text-[12px] md:text-xl lg:text-[16px] text-[#FCD462]">
                  {single?.productPrice}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TrendingCollection;
