import React from "react";
import Aarrow from "../../../assets/images/Arrow 8.svg";
import { generateImageUrl } from "../../../client";
const StartingPrice = ({ section }) => {

  return (
    <div className="container px-6 lg:pl-[86px] lg:pr-[91px] mx-auto pb-[4.5rem] pt-12 flex flex-wrap justify-between">
      {section?.productDetailsCards?.map((single, index) => (
        <div className="relative w-full lg:w-1/4 mb-6 lg:mb-0" key={index}>
          <div className="content-outer">
            <div
              data-aos="zoom-in-up"
              className="min-h-[422px] md:min-h-[890px] lg:min-h-[422px] w-100 relative"
              style={{
            backgroundImage: `url(${
              generateImageUrl(single?.productBackgroundImage?.asset?._ref) || ""
            })`,
            backgroundSize: `cover`,
            backgroundRepeat: `no-repeat`,
            backgroundPosition: `center`,
            loading: `lazy`,
          }}
            >
              <div className="flex flex-col items-center pt-[110px] md:pt-[280px] lg:pt-[110px] card-inner">
                <img
                  className="w-[34px] md:w-[66px] lg:w-[34px]"
                  src={generateImageUrl(single?.productIcone?.asset?._ref)}
                  alt="plus"
                  loading="lazy"
                />
                <p className="text-white text-3xl md:text-5xl lg:text-3xl carmorant-font font-bold py-4 z-0">
                  {single?.productName}
                </p>
                <div className="pt-[5rem]">
                  <button className="py-4 px-6  border-2 hover:bg-[#FCD462] text-[14px] md:text-xl lg:text-[10px] xl:text-[14px] text-white font-bold hover:text-black hover:border-transparent ease-in-out duration-300">
                   {single?.button?.cardButton}
                  </button>
                </div>
              </div>
              <div className="w-full color-overlay ease-in-out content-div shadow-lg group rounded-md bg-white flex justify-center items-center mx-auto">
                <div className="absolute inset-0 opacity-0  group-hover:opacity-100 flex flex-col justify-center">
                  <span className="font-bold text-white tracking-wider leading-relaxed font-sans">
                    <p className="opacityNone text-white text-3xl md:text-4xl lg:text-3xl carmorant-font font-bold py-4 z-0 text-center">
                    {single?.productDetailsOnHover?.productNameOnHover}
                    </p>
                    <p className="opacityNone text-[9px] md:text-[16px] lg:text-[9px] uppercase font-light text-center px-[20px]">
                    {single?.productDetailsOnHover?.detailsOnHover}
                    </p>
                  </span>
                  <button className="opacityNone outline-none flex items-center justify-center pt-[38px]">
                    <p className="text-[#FCD462] mr-2 text-[14px] md:text-[22px] lg:text-[14px]">
                    {single?.productDetailsOnHover?.button?.viewMore}
                    </p>
                    <img
                      className="w-[20px] pt-[3px]"
                      src={Aarrow}
                      alt="plus"
                    />
                  </button>
                  <div className="opacityNone pt-[3rem] flex justify-center">
                    <button className="py-4 px-6  bg-[#FCD462] text-[14px] md:text-xl lg:text-[10px] xl:text-[14px] text-white font-bold  hover:text-black  hover:border-transparent ease-in-out duration-300">
                      {single?.productDetailsOnHover?.StartingAtButton?.startingAt}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      
    </div>
  );
};

export default StartingPrice;
