import React from "react";
import { Link } from "gatsby";
import { useState } from "react";
import { generateImageUrl } from "../../client";

const SingleCard = ({ single, index }) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className=" lg:w-[18%] w-[47%] text-[#FCD462] hover:text-white "
      key={index}
      onMouseOver={() => {
        setOpen(true)
      }}
      onFocus={() => setOpen(true) }
      onBlur={() => setOpen(false) }
      
      onMouseOut={() => setOpen(false)}
      aria-hidden={true}
    
    >
      <Link to={single?.link}>
        <div
          data-aos="zoom-in-up"
          className=" bg-[#474742] mb-5 md:mb-[45px] lg:mb-0  min-h-[200px] hover:bg-[#FCD462] ease-in-out duration-300  cursor-pointer flex justify-center items-center flex-col"
        >
          <img
            className="w-20 pb-2"
            src={open ? generateImageUrl(single?.hoverImage?.asset?._ref).url() || "" : generateImageUrl(single?.image?.asset?._ref)}
            alt="ring"
            loading="lazy"
          />

          <h1 className="carmorant-font text-2xl md:text-4xl lg:text-3xl font-semibold pt-2">
            {single?.productName}
          </h1>
        </div>
      </Link>
    </div>
  );
};

export default SingleCard;
