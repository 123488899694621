import React from "react";
import { Link } from "gatsby";
import { generateImageUrl } from "../../client";


const SingleItemCommon = ({ single, index, saveProductNameInLocalStorage ,SiteController ,}) => {
 
  
    let weight = single?.productWeight?.match(/\d+/)[0];
  
  
  return (
    <>
      <div
        key={index}
        data-aos="zoom-in-up"
        className="w-full md:w-[31%] lg:w-[32%] xl:w-[32%] mb-6"
      >
        <Link
          to="/shop-details"
          onClick={() => {
            saveProductNameInLocalStorage(single?.productName);
          }}
        >
          <div
            className=" w-full p-4 min-h-[470px] md:min-h-[300px] lg:min-h-[300px]"
            style={{
              backgroundImage: `url(${generateImageUrl(
                single?.productImage?.asset?._ref
              )})`,
              backgroundPosition: `center`,
              backgroundSize: `cover`,
              loading: `lazy`,
            }}
          >
            
            <div className="flex justify-between">
              <div className="flex flex-col ">
                {single?.button?.saleButton && (
                  <button className="hover:text-black py-1 px-4 bg-[#FCD462] text-[10px] text-white font-bold ease-in-out duration-300">
                    {single?.button?.saleButton}
                  </button>
                )}
                {single?.button?.offSaleButton && (
                  <button className="mt-2 py-1 px-4 bg-white text-[10px] text-black font-bold ease-in-out duration-300">
                    {single?.button?.offSaleButton}
                  </button>
                )}
              </div>
              {single?.cartImage?.asset?._ref && (
                <div className="cursor-pointer traliIcon bg-[#423F3B] rounded-full px-[1px]  flex items-center ">
                  <img
                    className="w-[50px] "
                    src={generateImageUrl(single?.cartImage?.asset?._ref)}
                    alt="carticone"
                    loading="lazy"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="bg-[#353530] px-6 pt-4 pb-8">
            <p className="carmorant-font text-white text-xl font-semibold">
              {single?.productName}
            </p>
            <p className="font-bold pt-2 text-[10px] md:text-[14px] lg:text-[16px]">
           
              <span className="text-[#FCD462] mr-2">
                PKR {SiteController[0].price * weight}
              </span>
              {single?.productOffPrice && (
                <del className="text-white opacity-40">
                  PKR {single.productOffPrice}
                </del>
              )}
               
            </p>
            <p className="text-[#FCD462] mr-2">{single?.productWeight}</p>
          
          </div>
        </Link>
      </div>
    </>
  );
};

export default SingleItemCommon;
