import * as React from "react";
import SingleCard from "../../components/singleCard";

const JewelleryProducts = ({section}) => {

  return (
    <div className="flex justify-center flex-col items-center container  mx-auto">
      <div className="w-full flex justify-between flex-wrap ">
        {section?.ourCategories?.map((single, index) => (
          <SingleCard single={single} key={index} />
        ))}
      </div>
    </div>
  );
};

export default JewelleryProducts;
