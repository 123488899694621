import React from "react";
const Spacers = ({ section, className }) => {

  return section?.is_hidden ? (
    <div />
  ) : (
    <div
      id={section?.spacerId}
      className={
        section?.size === "s"
          ? `pb-12 lg:pb-20 ${className}`
          : section?.size === "m"
          ? `pb-30 lg:pb-36 ${className}`
          : `pb-34 lg:pb-44 ${className}`
      }
    />

  );
};
export default Spacers;
