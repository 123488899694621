import React from "react";
import Layout from "../components/layout";
import HeroSection from "../components/homePageComponent/heroSection";
import OurCategories from "../components/homePageComponent/Categories";
import HomepageAboutUs from "../components/homePageComponent/aboutUs";
import TrendingCollection from "../components/homePageComponent/trendingCollection";
import ProductDetails from "../components/homePageComponent/startingPrice";
import RareCollection from "../components/homePageComponent/rareCollections";
import BestSellingProducts from "../components/homePageComponent/bestSellingProducts";
import HandpickedProducts from "../components/homePageComponent/handpickedProducts";
import CommonHeroSection from "../components/aboutUsPageComponents/commonHeroSection";
import AboutUsVideo from "../components/aboutUsPageComponents/aboutUsVideo";
import AboutUsFeatures from "../components/aboutUsPageComponents/aboutUsFeatures";
import AboutUsFeedBack from "../components/aboutUsPageComponents/aboutUsFeedBack";
import CommonEmail from "../components/aboutUsPageComponents/aboutUsEmail";
import DiamondProducts from "../components/homePageComponent/diamondProducts";
import ProductRating from "../components/homePageComponent/productRating";
import SingleProductRating from "../components/shopDetailsComponent/productRating";
import Spacer from "../components/spacer";
import GoldCollectionProducts from "../components/goldCollectionPageComponents/jewelleryItems";
import GoldPriceTable from "../components/goldPriceComponent/rates";
import SelectedProduct from "..//components/viewCartComponent/selectedProduct";
import PaymentMathod from "../components/viewCartComponent/paymentMethod";
import BlogsHero from "../components/blogPageComponents/blogHero";

const IndexPage = ({
  pageContext: { page, Products, SiteController, Blogs },
}) => {
  const renderSections = (section, index) => {
    switch (section?._type) {
      case "homePageHero":
        return <HeroSection section={section} key={index} />;
      case "ourCategories":
        return <OurCategories section={section} key={index} />;
      case "homePageAboutUs":
        return <HomepageAboutUs section={section} key={index} />;
      case "trendingCollection":
        return <TrendingCollection section={section} key={index} />;
      case "productDetails":
        return <ProductDetails section={section} key={index} />;

      case "rareCollection":
        return <RareCollection section={section} key={index} />;

      case "bestSelling":
        return (
          <BestSellingProducts
            section={section}
            Products={Products}
            SiteController={SiteController}
            key={index}
          />
        );
      case "handPickedProducts":
        return <HandpickedProducts section={section} key={index} />;
      case "commonHero":
        return <CommonHeroSection section={section} key={index} />;
      case "youTubeVideo":
        return <AboutUsVideo section={section} key={index} />;
      case "ourFeatures":
        return <AboutUsFeatures section={section} key={index} />;
      case "feedBack":
        return <AboutUsFeedBack section={section} key={index} />;
      case "eMail":
        return <CommonEmail section={section} key={index} />;

      case "newCollection":
        return (
          <DiamondProducts
            section={section}
            Products={Products}
            SiteController={SiteController}
            key={index}
          />
        );

      case "homePageProductRating":
        return <ProductRating section={section} key={index} />;

      case "singleProductDetails":
        return (
          <SingleProductRating
            Products={Products}
            SiteController={SiteController}
            key={index}
          />
        );
      case "spacer":
        return <Spacer section={section} key={index} />;
      case "goldCollection":
        return (
          <GoldCollectionProducts
            section={section}
            Products={Products}
            SiteController={SiteController}
            key={index}
          />
        );

      case "customTable":
        return <GoldPriceTable section={section} key={index} />;

      case "cartProductDetails":
        return (
          <SelectedProduct
            section={section}
            key={index}
            Products={Products}
            SiteController={SiteController}
          />
        );
      case "payment":
        return <PaymentMathod section={section} key={index} />;
      case "blogsHero":
        return <BlogsHero section={section} key={index} allBlogs={Blogs} />;

     
      default:
        return <></>;
    }
  };
  return (
    <>
      <Layout
        title={page?.title}
        page={page}
        Blogs={Blogs}
        seo={page?.seo}
        socialsharing={page?.socialSharing}
        SiteController={SiteController}
      >
        <>
          {page?.content?.length ? (
            <div>
              {page?.content?.map((section, index) =>
                renderSections(section, index)
              )}
            </div>
          ) : (
            <div></div>
          )}
        </>
      </Layout>
    </>
  );
};

export default IndexPage;
