import React from "react";
import Replyicone from "../../../assets/images/reply.svg";
import whiteReplyicone from "../../../assets/images/whitereply.svg";
import PostComment from "../postComment";

const Comment = () => {
  return (
    <div className="container px-6 lg:pl-[86px] lg:pr-[91px] mx-auto">
    <p data-aos="fade-down" className="flex justify-center py-8 carmorant-font text-[30px] md:text-[55px] lg:text-[42px] text-white font-semibold">Reviews</p>

    <div className="Comment">
      <div className="bg-[#353530] pt-14 pb-8 px-4 lg:px-12 text-white">
        <p
          data-aos="fade-down"
          className="font-[900] text-2xl lg:text-4xl pb-6"
        >
          02 Reviews
        </p>

        <div
          data-aos="zoom-in-up"
          className="userComment pt-10 pb-8 flex border-t-[1px]"
        >
          <p className="profile-Picture bg-[#2F2E2D] flex justify-center py-6 lg:py-12 px-6 lg:px-10 w-[12%] mr-4 lg:mr-10 carmorant-font text-xl lg:text-4xl text-white font-semibold">
            JD
          </p>
          <div className="w-[78%]">
            <div className="flex justify-between w-full">
              <div>
                <p className="carmorant-font text-2xl lg:text-4xl font-bold">
                  John Doe
                </p>
                <p className="py-4 text-[14px]">19th May 2022</p>
                <p className="uppercase text-[8px] md:text-[10px] lg:text-[9px] font-light leading-[10px] md:leading-[18px] lg:leading-[20px]">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
          </div>
          <div className="flex mt-[26px]">
            <img
              className="w-[13px] lg:w-[20px] h-[20px] mr-2"
              src={Replyicone}
              alt="Replyicone"
            />
            <p className="text-[12px] lg:text-[14px] text-[#FCD462]">Reply</p>
          </div>
        </div>
        <div
          data-aos="zoom-in-up"
          className="userComment pt-10 pb-8 flex ml-[40px] lg:ml-[163px] border-t-[1px] "
        >
          <p className="profile-Picture bg-[#2F2E2D] flex justify-center py-6 lg:py-12 px-6 lg:px-10 w-[12%] mr-4 lg:mr-10 carmorant-font text-xl lg:text-4xl text-white font-semibold">
            JD
          </p>
          <div className="w-[78%]">
            <div className="flex justify-between w-full">
              <div>
                <p className="carmorant-font text-2xl lg:text-4xl font-bold">
                  John Doe
                </p>
                <p className="py-4 text-[14px]">19th May 2022</p>
                <p className="uppercase text-[8px] md:text-[10px] lg:text-[9px] font-light leading-[10px] md:leading-[18px] lg:leading-[20px]">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
          </div>
          <div className="flex mt-[26px]">
            <img
              className="w-[13px] lg:w-[20px] h-[20px] mr-2"
              src={whiteReplyicone}
              alt="whiteReplyicone"
            />
            <p className="text-[12px] lg:text-[14px]">Reply</p>
          </div>
        </div>
        <div
          data-aos="zoom-in-up"
          className="userComment pt-10 pb-8 flex border-t-[1px]"
        >
          <p className="profile-Picture bg-[#2F2E2D] flex justify-center py-6 lg:py-12 px-6 lg:px-10 w-[12%] mr-4 lg:mr-10 carmorant-font text-xl lg:text-4xl text-white font-semibold">
            JD
          </p>
          <div className="w-[78%]">
            <div className="flex justify-between w-full">
              <div>
                <p className="carmorant-font text-2xl lg:text-4xl font-bold">
                  John Doe
                </p>
                <p className="py-4 text-[14px]">19th May 2022</p>
                <p className="uppercase text-[8px] md:text-[10px] lg:text-[9px] font-light leading-[10px] md:leading-[18px] lg:leading-[20px]">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
          </div>
          <div className="flex mt-[26px]">
            <img
              className="w-[13px] lg:w-[20px] h-[20px] mr-2"
              src={whiteReplyicone}
              alt="whiteReplyicone"
            />
            <p className="text-[12px] lg:text-[14px]">Reply</p>
          </div>
        </div>
        <PostComment />
      </div>
    </div>
    </div>
  );
};

export default Comment;
