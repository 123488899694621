import React from "react";
import { generateImageUrl } from "../../../client";

const RareCollections = ({ section }) => {
  return (
    <div
      className="min-h-[467px]"
      style={{
        backgroundImage: `url(${
          generateImageUrl(section?.image?.asset?._ref) || ""
        })`,
        backgroundSize: `cover`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `center`,
        loading: `lazy`,
      }}
    >
      <div className="container px-6 lg:pl-[86px] lg:pr-[91px] mx-auto text-white pt-6 lg:pt-12 pb-10 lg:pb-20">
        <p
          data-aos="fade-down"
          className="py-4 uppercase text-[10px] md:text-xl lg:text-[15px]"
        >
          {section?.title}
        </p>
        <p
          data-aos="fade-down"
          className="leading-[5rem] carmorant-font text-4xl md:text-7xl lg:text-6xl font-semibold"
        >
          {section?.mainHeading}
        </p>
        <p
          data-aos="fade-down"
          className="text-[9px] md:text-[16px] lg:text-[12px] py-2 md:py-6 lg:py-6 w-[85%] lg:w-[50%] font-light"
        >
          {section?.description}
        </p>
        <div className="pt-[1rem]">
          <button
            data-aos="fade-down"
            className=" hover:text-black  py-3 lg:py-4 px-6 lg:px-8 border-2 hover:bg-[#FCD462] text-[16px] md:text-[30px] lg:text-xl text-white font-bold  hover:border-transparent ease-in-out duration-300"
          >
            {section?.button?.buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RareCollections;
