import React from "react";
import CraditCradSocialIcone from "../../../assets/images/cridetCardicone.svg";
import { useState } from "react";

const PymentMethod = () => {
  const [craditCard, setCraditCard] = useState(false);
  const [jazzCash, setJazzCash] = useState(false);
  const [hblBank, setHblBank] = useState(false);
  const [easyPaisa, setEasyPaisa] = useState(false);
  const [cashOn, setCashOn] = useState(false);

  const submitForm = (event) => {
    event.preventDefault();
  };

  return (
    <div className="my-20 flex justify-center">
      <form
        className="bg-[#393939] p-8 w-3/5 mx-auto"
        onSubmit={(event) => {
          submitForm(event);
        }}
      >
        <h1 className="text-center text-white carmorant-font text-xl font-bold">
          Select Payment Method
        </h1>
        <h2 className="text-center text-xl text-white py-4 font-bold">
          Order # 120992726
        </h2>

        <div className="dropDown  cursor-pointer border-[1px] border-[#696969] mb-6">
          <div
            onClick={() => {
              setCraditCard(!craditCard);
            }}
            style={{ background: `${craditCard ? "#FCD462" : ""}` }}
            className="ease-in-out duration-500 hover:bg-[#FCD462] px-6 py-4  text-white hover:text-black hover:font-bold"
            aria-hidden={true}
          >
            <div className="flex justify-between">
              <p
                style={{ color: `${craditCard ? "black" : ""}` }}
                className="carmorant-font text-[22px]"
              >
                Credit/Debit Card
              </p>
              <div className="flex">
                <img
                  className="w-[115px]"
                  src={CraditCradSocialIcone}
                  alt="CraditCradSocialIcone"
                />

                <span
                  className="ml-8"
                  style={{
                    transform: `${
                      craditCard ? "rotate(90deg)" : "rotate(-90deg)"
                    }`,
                  }}
                >
                  <svg
                    className="dropIcone"
                    style={{ color: `${craditCard ? "black" : ""}` }}
                    width="13"
                    height="33"
                    viewBox="0 0 17 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.28186 33L5.84768e-06 30.6667L12.436 15.84L2.76995e-06 2.33331L2.28186 0L17 15.84L2.28186 33Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          {craditCard && (
            <div
              className="showBox transition duration-600 ease-out p-8"
              data-aos="zoom-in-up"
            >
              <div className="py-2">
                <p className="text-white pl-6">Card Number</p>
                <div
                  data-aos="zoom-in-up"
                  className="bg-[#353530] px-4 lg:px-6 py-5 flex mt-2"
                >
                  <input
                    className="placeholderText text-white w-full bg-[#353530] outline-none text-[12px] lg:text-[16px]"
                    type="number"
                    placeholder="Card Number"
                    required
                  />
                </div>
              </div>
              <div className="py-2">
                <p className="text-white pl-6">Name On Card</p>
                <div
                  data-aos="zoom-in-up"
                  className="bg-[#353530] px-4 lg:px-6 py-5 flex mt-2"
                >
                  <input
                    className="placeholderText text-white w-full bg-[#353530] outline-none text-[12px] lg:text-[16px]"
                    type="text"
                    placeholder="Name On Card"
                    required
                  />
                </div>
              </div>
              <div className="py-2 flex justify-between">
                <div>
                  <p className="text-white pl-6">Expiration Date</p>
                  <div
                    data-aos="zoom-in-up"
                    className="bg-[#353530] px-4 lg:px-6 py-5 flex mt-2"
                  >
                    <input
                      className="placeholderText text-white w-full bg-[#353530] outline-none text-[12px] lg:text-[16px]"
                      type="month"
                      placeholder="MM/YY"
                      required
                    />
                  </div>
                </div>
                <div>
                  <p className="text-white pl-6">CVV</p>
                  <div
                    data-aos="zoom-in-up"
                    className="bg-[#353530] px-4 lg:px-6 py-5 flex mt-2"
                  >
                    <input
                      className="placeholderText text-white w-full bg-[#353530] outline-none text-[12px] lg:text-[16px]"
                      type="number"
                      placeholder="CVV"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="dropDown  cursor-pointer border-[1px] border-[#696969] mb-6">
          <div
            onClick={() => {
              setJazzCash(!jazzCash);
            }}
            style={{ background: `${jazzCash ? "#FCD462" : ""}` }}
            className="ease-in-out duration-500 hover:bg-[#FCD462] px-6 py-4  text-white hover:text-black hover:font-bold"
            aria-hidden={true}
          >
            <div className="flex justify-between">
              <p
                style={{ color: `${jazzCash ? "black" : ""}` }}
                className="carmorant-font text-[22px]"
              >
                JazzCash
              </p>
              <div className="flex">
                <span
                  className="ml-8"
                  style={{
                    transform: `${
                      jazzCash ? "rotate(90deg)" : "rotate(-90deg)"
                    }`,
                  }}
                >
                  <svg
                    className="dropIcone"
                    style={{ color: `${jazzCash ? "black" : ""}` }}
                    width="13"
                    height="33"
                    viewBox="0 0 17 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.28186 33L5.84768e-06 30.6667L12.436 15.84L2.76995e-06 2.33331L2.28186 0L17 15.84L2.28186 33Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          {jazzCash && (
            <form
              onSubmit={(event) => {
                submitForm(event);
              }}
              className="showBox transition duration-600 ease-out p-8"
              data-aos="zoom-in-up"
            >
              <p className="text-white pl-6 text-[12px]">
                ➊ FOR JAZZ/WARID <br />↳ Unlock your phone and you will receive
                a MPIN Input Prompt <br />
                ➋ FOR OTHER NETWORKS <br />↳ Log-in to your JazzCash App and
                enter your MPIN <br />
                Note: Ensure your JazzCash account is Active and has sufficient
                balance.
              </p>
              <div className="py-2">
                <p className="text-white pl-6">JazzCash Account Number</p>
                <div
                  data-aos="zoom-in-up"
                  className="bg-[#353530] px-4 lg:px-6 py-5 flex mt-2"
                >
                  <input
                    className="placeholderText text-white w-full bg-[#353530] outline-none text-[12px] lg:text-[16px]"
                    type="number"
                    placeholder=""
                    required
                  />
                </div>
              </div>
              <div className="flex items-start mb-6 pl-[24px] py-2">
                <div className="flex items-center h-5">
                  <input
                    id="remember"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                    required
                  />
                </div>
                <p className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Save your JazzCash Account for future use.
                </p>
              </div>
            </form>
          )}
        </div>

        <div className="dropDown  cursor-pointer border-[1px] border-[#696969] mb-6">
          <div
            onClick={() => {
              setHblBank(!hblBank);
            }}
            style={{ background: `${hblBank ? "#FCD462" : ""}` }}
            className="ease-in-out duration-500 hover:bg-[#FCD462] px-6 py-4  text-white hover:text-black hover:font-bold"
          aria-hidden={true}
          >
            <div className="flex justify-between">
              <p
                style={{ color: `${hblBank ? "black" : ""}` }}
                className="carmorant-font text-[22px]"
              >
                HBL Bank Account
              </p>
              <div className="flex">
                <span
                  className="ml-8"
                  style={{
                    transform: `${
                      hblBank ? "rotate(90deg)" : "rotate(-90deg)"
                    }`,
                  }}
                >
                  <svg
                    className="dropIcone"
                    style={{ color: `${hblBank ? "black" : ""}` }}
                    width="13"
                    height="33"
                    viewBox="0 0 17 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.28186 33L5.84768e-06 30.6667L12.436 15.84L2.76995e-06 2.33331L2.28186 0L17 15.84L2.28186 33Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          {hblBank && (
            <form
              onSubmit={(event) => {
                submitForm(event);
              }}
              className="showBox transition duration-600 ease-out p-8"
              data-aos="zoom-in-up"
            >
              <div className="py-2">
                <p className="text-white pl-6">HBL Account Number</p>
                <div
                  data-aos="zoom-in-up"
                  className="bg-[#353530] px-4 lg:px-6 py-5 flex mt-2"
                >
                  <input
                    className="placeholderText text-white w-full bg-[#353530] outline-none text-[12px] lg:text-[16px]"
                    type="number"
                    placeholder=""
                    required
                  />
                </div>
              </div>
              <div className="py-2">
                <p className="text-white pl-6">CNIC NO.</p>
                <div
                  data-aos="zoom-in-up"
                  className="bg-[#353530] px-4 lg:px-6 py-5 flex mt-2"
                >
                  <input
                    className="placeholderText text-white w-full bg-[#353530] outline-none text-[12px] lg:text-[16px]"
                    type="text"
                    placeholder=""
                    required
                  />
                </div>
              </div>
            </form>
          )}
        </div>

        <div className="dropDown  cursor-pointer border-[1px] border-[#696969] mb-6">
          <div
            onClick={() => {
              setEasyPaisa(!easyPaisa);
            }}
            aria-hidden={true}
            style={{ background: `${easyPaisa ? "#FCD462" : ""}` }}
            className="ease-in-out duration-500 hover:bg-[#FCD462] px-6 py-4  text-white hover:text-black hover:font-bold"
          >
            <div className="flex justify-between">
              <p
                style={{ color: `${easyPaisa ? "black" : ""}` }}
                className="carmorant-font text-[22px]"
              >
                EasyPaisa
              </p>
              <div className="flex">
                <span
                  className="ml-8"
                  style={{
                    transform: `${
                      easyPaisa ? "rotate(90deg)" : "rotate(-90deg)"
                    }`,
                  }}
                >
                  <svg
                    className="dropIcone"
                    style={{ color: `${easyPaisa ? "black" : ""}` }}
                    width="13"
                    height="33"
                    viewBox="0 0 17 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.28186 33L5.84768e-06 30.6667L12.436 15.84L2.76995e-06 2.33331L2.28186 0L17 15.84L2.28186 33Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          {easyPaisa && (
            <form
              onSubmit={(event) => {
                submitForm(event);
              }}
              className="showBox transition duration-600 ease-out p-8"
              data-aos="zoom-in-up"
            >
              <div className="pl-6">
                <p className="text-white text-[12px]">
                  Experience easy payments – save your Easypaisa account as
                  default method to pay! Please ensure your Easypaisa account is
                  Active and has sufficient balance.
                </p>
                <p className="text-white text-[12px] py-1">
                  To confirm your payment after providing OTP:
                </p>
                <p className="text-white text-[12px]">
                  - USSD prompt for Telenor Customers Only • Unlock your phone
                  and enter 5 digit PIN in the prompt to pay
                </p>
                <p className="text-white text-[16px] py-4">OR</p>
                <p className="text-white text-[12px]">
                  - Approve Payment in your Easypaisa App (Telenor and Other
                  Networks) • Login to Easypaisa App and tap on payment
                  notification to approve • If you miss the notification, go to
                  My Approvals in side menu to confirm
                </p>
              </div>
              <div className="py-2">
                <p className="text-white pl-6">Easypaisa Account Number</p>
                <div
                  data-aos="zoom-in-up"
                  className="bg-[#353530] px-4 lg:px-6 py-5 flex mt-2"
                >
                  <input
                    className="placeholderText text-white w-full bg-[#353530] outline-none text-[12px] lg:text-[16px]"
                    type="number"
                    placeholder=""
                    required
                  />
                </div>
              </div>
            </form>
          )}
        </div>

        <div className="dropDown  cursor-pointer border-[1px] border-[#696969] mb-6">
          <div
            onClick={() => {
              setCashOn(!cashOn);
            }}
            aria-hidden={true}
            style={{ background: `${cashOn ? "#FCD462" : ""}` }}
            className="ease-in-out duration-500 hover:bg-[#FCD462] px-6 py-4  text-white hover:text-black hover:font-bold"
          >
            <div className="flex justify-between">
              <p
                style={{ color: `${cashOn ? "black" : ""}` }}
                className="carmorant-font text-[22px]"
              >
                Cash On
              </p>
              <div className="flex">
                <span
                  className="ml-8"
                  style={{
                    transform: `${cashOn ? "rotate(90deg)" : "rotate(-90deg)"}`,
                  }}
                >
                  <svg
                    className="dropIcone"
                    style={{ color: `${cashOn ? "black" : ""}` }}
                    width="13"
                    height="33"
                    viewBox="0 0 17 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.28186 33L5.84768e-06 30.6667L12.436 15.84L2.76995e-06 2.33331L2.28186 0L17 15.84L2.28186 33Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          {cashOn && (
            <form
              onSubmit={(event) => {
                submitForm(event);
              }}
              className="showBox transition duration-600 ease-out p-8"
              data-aos="zoom-in-up"
            >
              <div className="py-2">
                <p className="text-white pl-6">
                  You can pay in cash to our courier when you receive the goods
                  at your doorstep.
                </p>
              </div>
            </form>
          )}
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            className="transition duration-600 ease-out mt-6 py-3 px-16 bg-[#FCD462] uppercase text-xl lg:text-[18px] xl:text-xl font-bold text-white hover:text-black hover:font-bold"
          >
            Confirm Order
          </button>
        </div>
      </form>
    </div>
  );
};

export default PymentMethod;
