import React from "react";
import { Link } from "gatsby";

const CartProductDetails = ({ allData, SiteController }) => {
  let totalPrice = 0;
  allData?.forEach((product) => {
    if (product.quantity > 0) {
      totalPrice =
        totalPrice +
        product.quantity *
          product?.productWeight?.match(/\d+/)[0] *
          SiteController[0]?.price;
    }
  });

  return (
    <>
      <div className="order-summery bg-[#393939] py-8 px-6 w-full lg:w-[30%] ml-0 lg:ml-4 mt-6 lg:mt-0">
        <div className="border-b-[1px] border-[#696969] pb-4">
          <div className="text-white border-l-[1px] border-[#FCD462] pl-4 pr-[36px]">
            <h1 className="carmorant-font text-xl lg:text-[16px] xl:text-xl font-bold">
              Order Summary
            </h1>
          </div>
          <h2 className="orderID text-white text-xl lg:text-[17px] xl:text-xl font-bold py-6">
            Order # 120992726
          </h2>
          <h3 className="selectitems text-white text-[18px] lg:text-[16px] xl:text-[18px] font-bold">
            Items
          </h3>
          <div className="flex justify-between pt-4">
            <p className="orderSumery text-white font-normal text-[18px] lg:text-[14px] xl:text-[18px]">
              Order Summary
            </p>
            <p className="text-[#FCD462] font-normal text-[17px] lg:text-[14px] xl:text-[17px]">
              PKR {totalPrice}
            </p>
          </div>
          <div className="flex justify-between pt-4">
            <p className="text-white font-normal text-[18px] lg:text-[14px] xl:text-[18px]">
              Delivery Charges
            </p>
            <p className="text-[#FCD462] font-normal text-[17px] lg:text-[14px] xl:text-[17px]">
              PKR 200
            </p>
          </div>
          <div className="flex justify-between pt-6">
            <p className="text-white font-bold text-[18px] lg:text-[14px] xl:text-[18px]">
              Total Amount
            </p>
            <p className="text-[#FCD462] text-xl font-normal text-[17px] lg:text-[14px] xl:text-[17px]">
              PKR {totalPrice + 200}
            </p>
          </div>
        </div>
        <form className="pt-6 pb-2">
          <div className="bg-[#1B1A18] py-2 px-4">
            <input
              className="w-full text-[10px] text-white checkoutPlacholder"
              type="text"
              placeholder="NAME*"
            />
          </div>

          <input
            className="bg-[#1B1A18] py-2 px-4 lowerCase my-4 w-full text-white checkoutPlacholder bg-[#1B1A18] outline-none"
            type="email"
            placeholder="EMAIL*"
          />

          <input
            className="py-2 px-4 bg-[#1B1A18] text-[14px] w-full text-[10px] text-white checkoutPlacholder outline-none"
            type="number"
            placeholder="PHONE NUMBER*"
          />
          <textarea
            className="textAreaPlacholder text-[14px] text-white mt-4 py-2 px-4 w-full bg-[#1B1A18] resize-none outline-none min-h-[120px]"
            placeholder="ADDRESS"
          ></textarea>
          <Link to="/payment">
            <button className="mt-4 py-3 px-6 bg-[#FCD462] w-full uppercase text-xl lg:text-[18px] xl:text-xl font-bold text-white hover:text-black hover:font-bold">
              Proceed To Pay
            </button>
          </Link>
        </form>
      </div>
    </>
  );
};
export default CartProductDetails;
