import * as React from "react";
import { Link } from "gatsby";
import { generateImageUrl } from "../../../client";

const CommonHero = ({ section }) => {
  return (
    <>
      <div
        className="aboutus-hero-bg flex justify-center  items-center relative "
        style={{
          backgroundImage: `url(${
            generateImageUrl(section?.mainBg?.asset?._ref) || ""
          })`,
          backgroundSize: `cover`,
          backgroundRepeat: `no-repeat`,
          backgroundPosition: `center`,
          loading:`lazy`,
        }}
      >
        <div className="text-white flex flex-col items-center">
          <h1 className="text-[10px] lg:text-[15px]">{section?.title}</h1>
          <h1 className="carmorant-font text-4xl lg:text-6xl font-semibold pt-4">
            {section?.subtitle}
          </h1>
        </div>
        <div className="flex bg-[#474742] px-6 py-4 absolute bottom-[-30px]">
          <Link to="/">
            <button className="text-white" type="button">
              {section?.homeButton?.homeButton}
            </button>
          </Link>
          <img
            className="px-4"
            src={generateImageUrl(section?.image?.asset?._ref)}
            alt="crystalimg"
            loading="lazy"
          />
          <button className="text-[#FCD462]" type="button">
            {section?.commonButton?.commonButton}
          </button>
        </div>
      </div>
    </>
  );
};

export default CommonHero;
