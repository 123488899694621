import * as React from "react";
import { useEffect, useState } from "react";
import search from "../../../assets/images/search.png";
import { restructorArray } from "../../../utils/utils";
import ReactPaginate from "react-paginate";
import { generateImageUrl } from "../../../client";
import { MemoizedMultiRangeSlider } from "../../../components/rangeslider";
import SingleItemCommon from "../../singleItemCommon";
import { Link } from "gatsby";

const JewelleryItems = ({ section, Products, SiteController }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [cards, setCards] = useState([]);
  const [backupData, setBackupData] = useState([]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  useEffect(() => {
    const productCategory = window.location.pathname.slice(1).split("-")[0];
    const filteredProducts = Products[0].content[0].allProducts.filter(
      (product) => {
        const productPrice =
          product.productWeight?.match(/\d+/)[0] * SiteController[0].price;
        return (
          product.category === productCategory &&
          productPrice >= minPrice &&
          productPrice <= maxPrice
        );
      }
    );
    setCards(restructorArray(filteredProducts, 9));
    setBackupData(restructorArray(filteredProducts, 9));
  }, [minPrice, maxPrice, Products, SiteController]);

  const searchHandler = (value) => {
    let filtered = backupData[0]?.filter(
      (item) =>
        item?.productName?.toUpperCase()?.indexOf(value.toUpperCase()) !== -1
    );
    setCards(restructorArray(filtered, 9));
  };

  const paginationHandler = (value) => {
    setCurrentPage(value.selected);
    window.scrollTo({ top: 100, behavior: "smooth" });
  };
  const saveProductNameInLocalStorage = (product_name) => {
    window.localStorage.setItem("productName", product_name);
  };
  return (
    <>
      <div className="container px-6 lg:pl-[86px] lg:pr-[91px] mx-auto my-20">
        <div className="flex w-full flex-col lg:flex-row">
          <div className="w-full flex flex-wrap lg:w-[23%]">
            <div
              data-aos="zoom-in-up"
              className="bg-[#353530] w-full md:w-[49%] lg:w-full flex flex-col justify-center px-4  py-5"
            >
              <h1 className="border-l-[1px] border-[#FCD462] carmorant-font text-white text-xl font-semibold pl-2 mb-4">
                Search Object
              </h1>
              <div className="bg-[#1C1B19] flex justify-between py-2">
                <input
                  className="text-white bg-[#1C1B19] px-2 py-1 w-full text-[9px] outline-none"
                  type="search"
                  placeholder="Search"
                  onChange={(event) => searchHandler(event.target.value)}
                />
                <div className="bg-[#FCD462] flex justify-center items-center px-4 py-1 mr-2 cursor-pointer">
                  <img src={search} alt="search" />
                </div>
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              className="bg-[#353530] w-full md:w-[49%] lg:w-full  flex flex-col justify-center px-4 py-5 mt-3 md:mt-0 lg:mt-3 ml-0 md:ml-3 lg:ml-0"
            >
              <div className="flex justify-between">
                <h1 className="border-l-[1px] border-[#FCD462] carmorant-font text-white text-xl font-semibold pl-2 mb-4">
                  Price
                </h1>
              </div>
              <MemoizedMultiRangeSlider
                className="bg-[#FCD462]"
                min={5}
                max={500}
                onChange={({ min, max }) => {
                  console.log(`min = ${min}, max = ${max}`);
                  setMinPrice(min * 1000);
                  setMaxPrice(max * 1000);
                }}
              />
            </div>
            <div
              data-aos="zoom-in-up"
              className="bg-[#353530] mr-0 md:mr-3 lg:mr-0 w-full md:w-[49%] lg:w-full flex flex-col justify-center px-4 mt-3 py-5"
            >
              <h1 className="border-l-[1px] border-[#FCD462] carmorant-font text-white text-xl font-semibold pl-2 mb-4">
                Color
              </h1>
              <div className="flex items-center">
                <input
                  className="h-4 w-4 outline-none "
                  type="checkbox"
                  id="Red"
                  name="Red"
                />
                <label className="text-white ml-2" htmlFor="Red">
                  Red
                </label>
              </div>
              <div className="flex items-center">
                <input
                  className="h-4 w-4 outline-none "
                  type="checkbox"
                  id="Green"
                  name="Green"
                />
                <label htmlFor="Green" className="text-white ml-2">
                  Green
                </label>
              </div>
              <div className="flex items-center">
                <input
                  className="h-4 w-4 outline-none"
                  type="checkbox"
                  id="Yellow"
                  name="Yellow"
                />
                <label htmlFor="Yellow" className="text-white ml-2">
                  Yellow
                </label>
              </div>
              <div className="flex items-center">
                <input
                  className=" h-4 w-4 outline-none"
                  type="checkbox"
                  id="Brown"
                  name="Brown"
                />
                <label className="text-white ml-2" htmlFor="Brown">
                  Brown
                </label>
              </div>
              <div className="flex items-center">
                <input
                  className=" h-4 w-4 outline-none"
                  type="checkbox"
                  id="Orange"
                  name="Orange"
                />
                <label className="text-white ml-2" htmlFor="Orange">
                  Orange
                </label>
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              className="bg-[#353530] w-full md:w-[49%] lg:w-full  flex  flex-col justify-center px-4 py-5 mt-3"
            >
              <h1 className="border-l-[1px] border-[#FCD462] carmorant-font text-white text-xl font-semibold pl-2 mb-4">
                {section?.shopType}
              </h1>
              <div className="flex flex-wrap gap-2 w-full mx-auto">
                {section?.shopTypeList?.map((single, index) => (
                  <Link to={single?.linkProduct} key={index}>
                    <div className="bg-[#1C1B19] hover:text-[#FCD462] cursor-pointer w-[97px] py-2 text-white text-sm flex  justify-center mb-2">
                      {single?.productName}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            <div
              data-aos="zoom-in-up"
              className="bg-[#353530] w-full md:w-[100%] lg:w-full flex flex-col justify-center px-4 py-5 mt-3"
            >
              <h1 className="border-l-[1px] border-[#FCD462] carmorant-font text-white text-xl font-semibold pl-2 mb-4">
                {section?.popularProduct}
              </h1>
              {section?.popularProductList?.map((single, index) => (
                <div key={index}>
                  <div className="flex py-4">
                    <img
                      className="w-16"
                      src={generateImageUrl(single?.productImage?.asset?._ref)}
                      alt="glodenring"
                      loading="lazy"
                    />
                    <div className="flex flex-col ml-3">
                      <h1 className="carmorant-font text-white text-xl font-semibold">
                        {single?.productName}
                      </h1>
                      <h1 className="text-[#FCD462] font-semibold">
                        {single?.productPrice}
                      </h1>
                    </div>
                  </div>
                  <div
                    className={`${
                      index === section?.popularProductList?.length - 1
                        ? ""
                        : "border-b-[1px] border-[#696969] pt-3"
                    }`}
                  ></div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex w-full md:w-full lg:w-[77%] gap-4 flex-wrap pt-8 lg:pt-0 ml-0 lg:ml-8 xl:ml-8">
            {cards?.[currentPage]?.map((single, index) => (
              <SingleItemCommon
                saveProductNameInLocalStorage={saveProductNameInLocalStorage}
                single={single}
                key={index}
                SiteController={SiteController}
              />
            ))}
          </div>
        </div>
        <ReactPaginate
          previousLabel={"<"}
          disabledClassName={"disableButton"}
          nextLabel={">"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={cards?.length}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={paginationHandler}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
};
export default JewelleryItems;
