import React from "react";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { time, date } from "../../../utils/utils";

const Rates = ({ section }) => {
  const [apiData, setApiData] = useState("");

  useEffect(() => {
    axios
      .get(
        "https://onlinegoldrates.com/page/PerTolaRate?id=f56184fb-7073-4113-a278-67a1abcf9b3d"
      )
      .then((res) => {
        setApiData(parseInt(res.data.replace("-", "")));
       
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  let twentyFourKarat = apiData;
  let twentyThreeKarat = apiData - apiData * 0.041;
  let twentyTwoKarat = apiData - apiData * 0.0865;
  let twentyOneKarat = apiData - apiData * 0.127;
  let twentyKarat = apiData - apiData * 0.167;

  return (
    <div className="container px-6 lg:pl-[163px] lg:pr-[145px] mx-auto pt-10 lg:pt-[75px] pb-4">
      <p className="text-white text-center text-[14.5px]">
        LAST UPDATED : {date} {time}
      </p>

      <div className="flex justify-between pt-4 flex-col lg:flex-row">
        <div data-aos="zoom-in-down" className="w-full lg:w-[47%] pb-6 lg:pb-0">
          <p className="carmorant-font text-[22px] text-white font-semibold pb-2">
            {section?.goldTable[0]?.tableHeading}
          </p>

          <table className="border-solid border-[1px] border-gray-500 w-full">
            <tbody>
              {section?.goldTable[0]?.table?.rows?.map((single, index) => (
                <tr
                  key={index}
                  className={`cursor-pointer hover:text-[#FCD462] hover:bg-[#353530] ease-in-out duration-300 text-white border-solid border-[1px] border-gray-500 ${
                    index === 0 &&
                    "text-[#FCD462] cursor-default hover:bg-[#1c1b19]"
                  }`}
                >
                  {single?.cells?.map((value, index) => (
                    <td
                      key={index}
                      className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px]"
                    >
                      {value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div data-aos="zoom-in-down" className="w-full lg:w-[47%]">
          <p className="carmorant-font text-[22px] text-white font-semibold pb-2">
            Google Rate
          </p>
          <table className="border-solid border-[1px] border-gray-500 w-full">
            <tbody>
              <tr className="cursor-pointer text-[#FCD462] bg-[#1c1b19] ease-in-out duration-300 text-white border-solid border-[1px] border-gray-500 ">
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px]">
                  Karat
                </td>
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px]">
                  Tola
                </td>
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px]">
                  Gram
                </td>
              </tr>
              <tr className="cursor-pointer hover:text-[#FCD462] hover:bg-[#353530] ease-in-out duration-300 text-white border-solid border-[1px] border-gray-500  ">
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px] ">
                  24 Karat
                </td>
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px] ">
                  {twentyFourKarat}
                </td>
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px] ">
                  {Math.round(twentyFourKarat / 11.6638038)}
                </td>
              </tr>
              <tr className="cursor-pointer hover:text-[#FCD462] hover:bg-[#353530] ease-in-out duration-300 text-white border-solid border-[1px] border-gray-500  ">
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px] ">
                  23 Karat
                </td>
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px] ">
                  {Math.round(twentyThreeKarat)}
                </td>
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px] ">
                  {Math.round(twentyThreeKarat / 11.6638038)}
                </td>
              </tr>
              <tr className="cursor-pointer hover:text-[#FCD462] hover:bg-[#353530] ease-in-out duration-300 text-white border-solid border-[1px] border-gray-500  ">
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px] ">
                  22 Karat
                </td>
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px]">
                  {Math.round(twentyTwoKarat)}
                </td>
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px] ">
                  {Math.round(twentyTwoKarat / 11.6638038)}
                </td>
              </tr>
              <tr className="cursor-pointer hover:text-[#FCD462] hover:bg-[#353530] ease-in-out duration-300 text-white border-solid border-[1px] border-gray-500  ">
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px] ">
                  21 Karat
                </td>
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px] ">
                  {Math.round(twentyOneKarat)}
                </td>
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px] ">
                  {Math.round(twentyOneKarat / 11.6638038)}
                </td>
              </tr>
              <tr className="cursor-pointer hover:text-[#FCD462] hover:bg-[#353530] ease-in-out duration-300 text-white border-solid border-[1px] border-gray-500  ">
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px] ">
                  20 Karat
                </td>
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px] ">
                  {Math.round(twentyKarat)}
                </td>
                <td className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px] ">
                  {Math.round(twentyKarat / 11.6638038)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Rates;
