import React from "react";
import { generateImageUrl } from "../../../client";

const AboutUsFeedBack = ({ section }) => {
  return (
    <div className="container px-6 lg:pl-[86px] lg:pr-[91px] mx-auto py-16">
      <div className="flex flex-col ">
        <h1
          data-aos="zoom-out"
          className="text-white text-[10px] lg:text-[15px]"
        >
          {section?.heading}
        </h1>
        <h1
          data-aos="zoom-out"
          className="text-white carmorant-font text-4xl lg:text-6xl  font-semibold py-6"
        >
          {section?.subHeading}
        </h1>

        <div className="flex justify-between flex-wrap">
          {section?.feedBack?.map((single, index) => (
            <div
              data-aos="zoom-in"
              className="shadow hover:shadow-[#FCD462] bg-[#474742] min-h-[130px] md:min-h-[220px] lg:min-h-[130px] w-full md:w-[30%] lg:w-[30%] mb-4 lg:mb-0 flex flex-row md:flex-col lg:flex-row justify-between items-center px-12 md:px-4 py-0 md:py-6 lg:py-0 lg:px-6 xl:px-12"
              key={index}
            >
              <img
                className="w-20 lg:w-16 xl:w-20 cursor-pointer"
                src={generateImageUrl(single?.image?.asset?._ref)}
                alt="group"
                loading="lazy"
              />
              <div className="flex flex-col items-center">
                <h1 className="text-4xl text-white">{single?.number}</h1>
                <h1 className="text-white opacity-50 pt-2">
                  {single?.comment}
                </h1>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-between mr-0 lg:mr-0 xl:mr-4 pt-6">
          <img
            className="w-[49%] boxhover cursor-pointer shadow hover:shadow-[#FCD462]"
            src={generateImageUrl(section?.diamondImage?.asset?._ref)}
            alt="necklace"
            loading="lazy"
          />
          <img
            className="w-[49%] boxhover cursor-pointer shadow hover:shadow-[#FCD462] "
            src={generateImageUrl(section?.necklaceImage?.asset?._ref)}
            alt="twonecklace"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUsFeedBack;
