import * as React from "react";
const AboutusVideo = ({section}) => {
  return (
    <div className="px-6 lg:pl-[86px] lg:pr-[91px] container mx-auto  pt-20 w-full">
      <iframe
        className="h-[600px] aboutusVideo"
        width="100%"
        title="atributesvideo"
        loading="lazy"
        src={section?.videoUrl}
      ></iframe>
    </div>
  );
};

export default AboutusVideo;
