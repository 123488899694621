import React from "react";

const AboutUsFeatures = ({ section }) => {
  return (
    <div className="bg-[#353530] py-12">
      <div className="container px-6 lg:pl-[86px] lg:pr-[91px] mx-auto flex justify-center flex-col items-center">
        <h1
          data-aos="zoom-out"
          className="text-white text-[10px] lg:text-[15px]"
        >
          {section?.title}
        </h1>
        <h1
          data-aos="zoom-out"
          className="text-white leading-[5rem] carmorant-font text-4xl lg:text-6xl font-semibold mx-auto text-center my-0 lg:my-4"
        >
          {section?.mainHeading}
        </h1>
        <div className="w-full flex flex-wrap justify-between">
          {section?.ourfeatures?.map((single, index) => (
            <div
              data-aos="zoom-in"
              className="shadow hover:shadow-[#FCD462] bg-[#2F2E2D] w-full lg:w-[30%] min-h-[300px] md:min-h-[400px] lg:min-h-[300px] flex flex-col  justify-center px-10 mt-6"
              key={index}
            >
              <h1 className="text-7xl font-semibold text-[#FCD462]">
                {single.number}
              </h1>
              <h1 className="carmorant-font text-white text-2xl md:text-4xl lg:text-[20px] xl:text-2xl font-semi pt-4 pb-2">
                {single.heading}
              </h1>
              <p className="text-white text-xs md:text-[1.2rem] md:leading-[2rem] lg:text-[11px] xl:text-xs">
                {single.description}{" "}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUsFeatures;
