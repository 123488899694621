import React from "react";
import Pencile from "../../../assets/images/pencile.svg";
import Email from "../../../assets/images/emailicone.png";
import UserIcone from "../../../assets/images/usericonee.svg";


const PostComment = () => {
  return (
    <>
      <div className="py-8">
        <p data-aos="fade-down" className="carmorant-font text-2xl lg:text-4xl font-bold">Post Comment</p>
      </div>
      <form className="bg-[#1c1b19] pt-8 pb-6 px-4 lg:px-10">
            <div data-aos="zoom-in-up" className="bg-[#353530] px-4 lg:px-8 py-4 flex mb-6">
                <textarea className="placeholderText w-full bg-[#353530] resize-none outline-none uppercase text-[12px] lg:text-[16px] min-h-[150px]" name="postcomment" placeholder="type your comment" form="usrform"></textarea>
                <img className="mb-[126px] lg:mb-[110px] w-[18px] md:w-[26px] lg:w-[28px]" src={Pencile} alt="pencile"/>
            </div>
            <div data-aos="zoom-in-up" className="bg-[#353530] px-4 lg:px-8 py-5 flex mb-6">
                <input className="placeholderText w-full bg-[#353530] outline-none uppercase text-[12px] lg:text-[16px]" name="postcomment" placeholder="Type your name" form="usrform" />
                <img className="w-[18px] md:w-[26px] lg:w-[30px] h-[26px]" src={UserIcone} alt="isericone"/>
            </div>
            <div data-aos="zoom-in-up" className="bg-[#353530] px-4 lg:px-8 py-5 flex">
                <input className="placeholderText w-full bg-[#353530] outline-none uppercase text-[12px] lg:text-[16px]" type="email" name="postcomment" placeholder="Type your name" form="usrform" />
                <img className="w-[22px] md:w-[28px] lg:w-[30px] h-[22px]" src={Email} alt="email"/>
            </div>

            <button data-aos="zoom-in-up" className="mt-8 mb-4 py-3 px-8 lg:px-12 border-2 hover:bg-[#FCD462] text-[14px] md:text-[18px] lg:text-xl hover:border-transparent ease-in-out duration-300 uppercase">post</button>
      </form>
    </>
  );
};

export default PostComment;
