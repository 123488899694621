import React from "react";
import { useState, useEffect } from "react";
import { generateImageUrl } from "../../../client";
import CartProductDetails from "..//..//viewCartComponent/cartProductDetails";

const SelectedProducts = ({ SiteController}) => {
  const [allData, setAllData] = useState([]);
  //////////////////////////////////////////////////////////////////////
 
 
 
  useEffect(() => {
    const cartProducts = JSON.parse(localStorage.getItem("allData"));
    setAllData(cartProducts);
  }, []);
  function updateProducts(productName, status) {
    const newData = allData.map((product) =>
      product.productName === productName
        ? {
            ...product,
            quantity:
              status === "increase"
                ? product.quantity + 1
                : product.quantity > 0
                ? product.quantity - 1
                : product.quantity,
          }
        : product
    );
    setAllData(newData);
    localStorage.setItem("allData", JSON.stringify(newData));
  }
  function removeProduct(productName) {
    const newData = allData.map((product) =>
      product.productName === productName
        ? { ...product, quantity: 0 }
        : product
    );
    setAllData(newData);
    localStorage.setItem("allData", JSON.stringify(newData));
  }


  return (
    <div className="flex container px-6 lg:pl-[86px] lg:pr-[91px] mx-auto my-24  flex-col lg:flex-row">
      {allData?.length ? (
        <>
        <div className="flex-1 px-6  py-4   bg-[#353530]">
           <div className="text-white flex justify-between border-l-[1px] border-[#FCD462] pl-4 pr-0 lg:pr-[36px]">
          <h2 className="carmorant-font text-[18px] lg:text-xl font-bold">
            Products Details
          </h2>
          <p className="text-[18px] lg:text-[20px] font-bold">
             Items
          </p>
        </div>
       
          {allData
            .filter((product) => product.quantity >= 1)
            .map((single, index) => (
              
              <div
                className=" Selected-item py-4  flex flex-col md:flex-row lg:flex-row justify-between pr-0 lg:pr-[36px] border-b-[1px] border-[#696969]"
                key={index}
              >
                <div className="flex justify-between w-full md:w-[40%] lg:w-[40%] ">
                  <div className="flex">
                    {single?.productImage && (
                      <img
                        className="w-[60px] md:w-[70px]"
                        src={generateImageUrl(
                          single?.productImage?.asset?._ref || ""
                        )}
                        alt="productimage"
                        loading="lazy"
                      />
                    )}
                    <div className="pl-4">
                      <h1 className="carmorant-font text-[18px] lg:text-[17px] xl:text-[21px] font-bold text-white">
                        {single?.productName}
                      </h1>
                   
                      <button
                        id={single.productName}
                        onClick={(e) => {
                          removeProduct(e.target.id);
                        }}
                        className="text-[#F53838]"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between w-full md:w-[58%] lg:w-[54%] xl:w-[58%] pt-6 md:pt-0 lg:pt-0">
                  <div className="">
                    <h1 className="carmorant-font text-[16px] md:text-[21px] lg:text-[17px] xl:text-[21px] font-bold text-white">
                      Price
                    </h1>
                    <p className="text-[#FCD462] text-[12px] md:text-[14px] lg:text-[12px] xl:text-[14px] font-bold py-[5px]">
                      {single?.productWeight?.match(/\d+/)[0] *
                        SiteController[0].price}
                    </p>
                    <del className="text-[#C4C4C4] text-[12px] md:text-[14px] lg:text-[12px] xl:text-[14px] font-bold py-[5px]">
                      PKR 80K
                    </del>
                  </div>
                  <div className="text-center">
                    <h1 className="carmorant-font text-[16px] md:text-[21px] lg:text-[17px] xl:text-[21px] font-bold text-white">
                      Quantity
                    </h1>
                    <div className="flex items-center justify-center lg:justify-start pt-[4px]">
                      <span className="flex text-white">
                        <button
                          id={single.productName}
                          className="py-0 lg:py-0 px-2  hover:bg-[#FCD462] text-[14px] md:text-2xl lg:text-xl xl:text2xl hover:border-transparent ease-in-out duration-300"
                          onClick={(e) => {
                            console.log(e.target);
                            updateProducts(e.target.id, "decrease");
                          }}
                        >
                          -
                        </button>
                        <p className="flex items-center justify-center bg-[#1B1A18] text-center py-0 lg:py-0 text-[12px] md:text-[18px] font-bold w-[52px] md:w-[85px] lg:w-[50px] xl:w-[65px]">
                          {single?.quantity}
                        </p>
                        <button
                          id={single.productName}
                          className="py-0 lg:py-0 px-2 hover:bg-[#FCD462] text-[14px] md:text-2xl lg:text-xl xl:text2xl hover:border-transparent ease-in-out duration-300"
                          onClick={(e) => {
                            updateProducts(e.target.id, "increase");
                          }}
                        >
                          +
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <h1 className="carmorant-font text-[16px] md:text-[21px] lg:text-[17px] xl:text-[21px] font-bold text-white">
                      Total
                    </h1>
                    <p className="text-[#FCD462] text-[12px] md:text-[14px] lg:text-[12px] xl:text-[14px] font-bold py-[5px]">
                      {single?.quantity *
                        single?.productWeight?.match(/\d+/)[0] *
                        SiteController[0].price}
                    </p>
                  </div>
                
                </div>
               
           
              </div>
                
            ))}
            </div> 
        </>
         
      ) : (
        <div className="text-3xl text-[#FCD462] font-semibold text-center mt-16">
          Your Cart Is Empty
        </div>
      )}
       
       <CartProductDetails allData={allData}
        SiteController={SiteController}  /> 
    </div>
  );
};
export default SelectedProducts;
