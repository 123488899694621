import React from "react";
import { generateImageUrl } from "../../../client";

const ProductRating = ({section}) => {
    return (
        <div className="bg-[#353530]">
            <div className="container px-6 lg:pl-[86px] lg:pr-[91px] mx-auto pt-10 pb-12 flex flex-wrap justify-between">
                {section?.product?.map((single,index)=>(
                <div data-aos="zoom-in-up" key={index} className="text-white flex flex-col items-center mb-10 lg:mb-0">
                    <img className="w-[60px] lg:w-[70px]"   src={generateImageUrl(single?.image?.asset?._ref)} alt="productimage" loading="lazy"/>
                    <p className="carmorant-font text-3xl lg:text-5xl font-semibold py-2">{single.title}</p>
                    <p className="font-semibold text-[14px] lg:text-[16px]">{single.discription}</p>
                </div>
                ))}
            </div>
    
        </div>
    )
};

export default ProductRating;