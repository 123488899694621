import React from "react";
import Aarrow from "../../../assets/images/Arrow 8.svg";
import { Link } from "gatsby";
import { generateImageUrl } from "../../../client";

const DiamondProducts = ({ section, Products, SiteController }) => {
  const saveProductNameInLocalStorage = (product_name) => {
    typeof window !== "undefined" &&
      window.localStorage.setItem("productName", product_name);
  };
  return (
    <div className="container px-6 lg:pl-[86px] lg:pr-[91px] mx-auto pb-20 text-white">
      <div className="flex flex-col items-center" data-aos="fade-down">
        <p className="py-4 uppercase text-white text-[10px] md:text-xl lg:text-[15px]">
          {section?.title}
        </p>
        <p className="leading-[3rem] lg:leading-[5rem] carmorant-font text-4xl md:text-7xl lg:text-6xl font-semibold text-white">
          {section?.mainHeading}
        </p>
        <button className="flex items-center my-6">
          <p className="text-[#FCD462] mr-2 text-[14px] md:text-[22px] lg:text-[14px]">
            {section?.button?.viewMore}
          </p>
          <img
            className="w-[20px] pt-[3px]"
            src={Aarrow}
            alt="arrow"
            loading="lazy"
          />
        </button>
      </div>
      <div className="flex mt-8 justify-between flex-wrap">
        {Products[0].content[0].allProducts.slice(0, 4).map((single, index) => (
          <div
            data-aos="zoom-in-up"
            className="cursor-pointer slider w-[48%] lg:w-[24%] pb-4 md:pb-6 lg:pb-0"
            key={index}
          >
            <Link
              to="/shop-details"
              onClick={() => {
                saveProductNameInLocalStorage(single.productName);
              }}
            >
              <div
                className="p-2 lg:p-4 min-h-[250px] md:min-h-[360px] lg:min-h-[300px]"
                style={{
                  backgroundImage: `url(${
                    generateImageUrl(single?.productImage?.asset?._ref) || ""
                  })`,
                  backgroundSize: `cover`,
                  backgroundRepeat: `no-repeat`,
                  backgroundPosition: `center`,
                  loading: `lazy`,
                }}
              >
                <div className="flex justify-between" key={index}>
                  <div className="flex flex-col w-[33%] lg:w-[26%]">
                    <button className="hover:text-black py-1 px-4 bg-[#FCD462] text-[8px] lg:text-[10px] text-white font-bold ease-in-out duration-300">
                      {single?.button?.saleButton}
                    </button>
                    {single?.button?.offSaleButton && (
                      <button className="mt-2 py-1 px-2 lg:px-0 xl:px-4 bg-white text-[8px] lg:text-[10px] text-black font-bold ease-in-out duration-300">
                        {single?.button?.offSaleButton}
                      </button>
                    )}
                  </div>
                  {single?.cartImage?.asset?._ref && (
                    <div className="cursor-pointer traliIcon bg-[#423F3B] rounded-full px-[1px]  flex items-center ">
                      <img
                        className="w-[50px]"
                        src={generateImageUrl(single?.cartImage?.asset?._ref)}
                        alt="carticone"
                        loading="lazy"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="bg-[#353530] px-6 pt-4 pb-4  lg:pb-8">
                <p className="carmorant-font text-[16px] md:text-2xl lg:text-xl font-semibold">
                  {single.productName}
                </p>
                <p className="font-bold pt-2 text-[8px] md:text-[14px] lg:text-[16px]">
                  <span className="text-[#FCD462] mr-2">
                    PKR{" "}
                    {single?.productWeight?.match(/\d+/)[0] *
                      SiteController[0].price}
                  </span>
                  {single?.productOffPrice && (
                    <del className="text-[10px] md:text-[14px] lg:text-[16px]">
                      PKR {single.productOffPrice}
                    </del>
                  )}
                </p>
                <p className="text-[#FCD462] mr-2">{single?.productWeight}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DiamondProducts;
