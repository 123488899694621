import React from "react";
import {generateImageUrl} from "../../../client"

const AboutUsEmail = ({section}) => {
 
 
  return (
    <div data-aos="zoom-in-up" className=" bg-[#353530] pt-10 pb-14 ">
      <div className="container mx-auto ">
          <div className="w-[85%] lg:w-[57%]  mx-auto flex flex-col justify-center items-center ">
        <img className="w-[38%] cursor-pointer" src={generateImageUrl(section?.logoImage?.image?.asset?._ref)} alt="logo" loading="lazy" />
        <p className="text-white text-[9px] lg:text-[10px] font-light leading-[15px] lg:leading-5 text-center uppercase py-6">
        {section?.description}
        </p>
        <div className=" w-full py-2 flex bg-[#1C1B19]">
            <input className="bg-[#1C1B19] text-white w-full pl-6 lg:pl-10 pr-4 lg:pr-8 outline-none text-xs uppercase " type="email" placeholder="Enter Your email here"/>
        <button className="text-white text-[12px] lg:text-xl hover:text-black font-semi px-4 py-3 my-2 bg-[#FAD66F] mr-4 hover:bg-[#FCD432] duration-300 ease-in-out  " type="button" >SUBSCRIBE</button></div>
        </div>
    
      </div>
    </div>
  );
};

export default AboutUsEmail;
