import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Link } from "gatsby";
import { generateImageUrl } from "../../../client";

const BestSellingProducts = ({ section, Products, SiteController }) => {
  const saveProductNameInLocalStorage = (product_name) => {
    typeof window !== "undefined" &&
      window.localStorage.setItem("productName", product_name);
  };

  return (
    <div className="container pl-6 pr-0 lg:pl-[86px] lg:pr-[91px] mx-auto text-white pt-12 lg:pt-20 pb-10 lg:pb-52 flex flex-col lg:flex-row">
      <div className="w-full lg:w-[48%]">
        <p
          data-aos="fade-down"
          className="py-4 uppercase text-[10px] md:text-xl lg:text-[15px]"
        >
          {section?.title}
        </p>
        <p
          data-aos="fade-down"
          className="leading-[3rem] lg:leading-[3rem] xl:leading-[5rem] carmorant-font text-4xl md:text-7xl lg:text-[40px] xl:text-[57px] font-semibold"
        >
          {section?.mainHeading}
        </p>
        <p
          data-aos="fade-down"
          className="text-[12px] md:text-[16px] lg:text-[12px] py-3 md:py-6 lg:py-6 font-light pr-[28px]"
        >
          {section?.description}
        </p>
        <div className="pt-[1rem]" data-aos="fade-up">
          <button className="hover:text-black py-3 lg:py-4 px-6 lg:px-8 border-2 hover:bg-[#FCD462] text-[16px] md:text-[30px] lg:text-xl text-white font-bold  hover:border-transparent ease-in-out duration-300">
            {section?.button?.buttonText}
          </button>
        </div>
      </div>

      <div
        data-aos="zoom-in-up"
        className="pt-10 lg:pt-0 w-full lg:w-[50%] relative lg:absolute right-0"
      >
        <Swiper
          spaceBetween={50}
          slidesPerView={2.5}
          setWrapperSize={100}
          className="absolute"
        >
          {Products[0].content[0].allProducts.map((single, index) => (
            <SwiperSlide key={index}>
              <div
                className="slider w-[165px] md:w-full lg:w-[230px] xl:w-[286px] pr-4 "
                key={index}
              >
                <Link
                  to="/shop-details"
                  onClick={() => {
                    saveProductNameInLocalStorage(single.productName);
                  }}
                >
                  {single?.productImage?.asset?._ref && (
                    <div
                      className="p-2 lg:p-4 min-h-[250px] lg:min-h-[300px]"
                      style={{
                        backgroundImage: `url(${
                          generateImageUrl(single?.productImage?.asset?._ref) ||
                          ""
                        })`,
                        backgroundSize: `cover`,
                        backgroundRepeat: `no-repeat`,
                        backgroundPosition: `center`,
                        loading: `lazy`,
                      }}
                    >
                      <div className="flex justify-between">
                        <div className="flex flex-col w-[32%] lg:w-[26%]">
                          <button className="hover:text-black  py-1 px-2 lg:px-4 bg-[#FCD462] text-[8px] lg:text-[10px] text-white font-bold ease-in-out duration-300">
                            {single?.button?.saleButton}
                          </button>
                          {single?.button?.offSaleButton && (
                            <button className="mt-2 py-1 px-2 lg:px-0 xl:px-4 bg-white text-[8px] lg:text-[10px] text-black font-bold ease-in-out duration-300">
                              {single?.button?.offSaleButton}
                            </button>
                          )}
                        </div>

                        <div className="cursor-pointer traliIcon bg-[#423F3B] rounded-full px-[1px]  flex items-center ">
                          {single?.cartImage?.asset?._ref && (
                            <img
                              className="w-[50px]"
                              src={generateImageUrl(
                                single?.cartImage?.asset?._ref
                              )}
                              alt="cartIcon"
                              loading="lazy"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="bg-[#353530] px-6 pt-4 pb-4 lg:pb-8">
                    <p className="carmorant-font text-[16px] md:text-2xl lg:text-xl font-semibold">
                      {single?.productName}
                    </p>
                    <p className="font-bold pt-2 text-[8px] md:text-[14px] lg:text-[16px]">
                      <span className="text-[#FCD462] mr-2">
                        PKR{" "}
                        {single?.productWeight?.match(/\d+/)[0] *
                          SiteController[0].price}
                      </span>
                      {single?.productOffPrice && (
                        <del className="text-[10px] md:text-[14px] lg:text-[16px]">
                          PKR {single?.productOffPrice}
                        </del>
                      )}
                    </p>
                    <p className="text-[#FCD462] mr-2">
                      {single?.productWeight}
                    </p>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default BestSellingProducts;
