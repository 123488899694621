import * as React from "react";
import { useState, useEffect } from "react";
import { Link } from "gatsby";
import moment from 'moment';
import { generateImageUrl } from "../../../client";
import { restructorArray } from "../../../utils/utils";
import ReactPaginate from "react-paginate";
const BlogsHero = ({ section, allBlogs }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [blogs, setBlogs] = useState();

  useEffect(() => {
    let _sortedBlogs=allBlogs.sort(function(a, b) { 
      a = new Date(a.date);
      b = new Date(b.date);
      return a >b ? -1 : a < b ? 1 : 0;
     })
    setBlogs(restructorArray(_sortedBlogs, 8));
   
  }, []);
  const paginationHandler = (value) => {
    setCurrentPage(value.selected);
  };

  return (
    <>
      <div
        data-aos="zoom-in"
        className="w-full min-h-[300px] flex justify-center items-center"
        style={{
          backgroundImage: `url(${generateImageUrl(
            section?.blogsImage?.asset?._ref
          )})`,
          backgroundSize: `cover`,
          backgroundRepeat: `no-repeat`,
          backgroundPosition: `center`,
          loading: `lazy`,
        }}
      ></div>
      {blogs?.length ? (
        <div
          data-aos="zoom-in"
          className="pt-12 w-full flex gap-1 flex-wrap lg:pl-86 lg:pr-91 pl-0 pr-0 mx-auto "
        >
          {blogs[currentPage].map((single, index) => (
            <div
              key={index}
              className=" lg:w-[22%] w-full lg:mx-2 mx-4 bg-[#353530] mt-6 pb-4 boxhover cursor-pointer shadow hover:shadow-[#FCD462]"
            >
              <Link to={single?.slug?.current}>
                {single?.heroImage?.asset?._ref && (
                  <img
                    className="h-[100px] w-full "
                    alt="blogImage"
                    src={generateImageUrl(single?.heroImage?.asset?._ref)}
                  />
                )}

                <div className="px-4 min-h-[20px]">
                  <h1 className="text-white text-2xl font-semi pt-4 ">
                    {single?.title}
                  </h1>
                  <p className="text-white text-base font-semi py-4">
                    {single?.shortDescription.substring(0, 70)} ...
                  </p>
                  <p className="text-white text-base font-semi py-4">
                    { moment(single?.date).format("DD-MM-YYYY")}
                  </p>
                  <Link to={single?.slug?.current}>
                    <button className=" text-[#FCD462] hover:text-white ease-in-out duration-300">
                      Read More
                    </button>
                  </Link>
                </div>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <h1 className="text-white text-2xl font-semi py-24 text-center">
          No Blogs!
        </h1>
      )}
      <ReactPaginate
        previousLabel={"<"}
        disabledClassName={"disableButton"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={blogs?.length}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={paginationHandler}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </>
  );
};
export default BlogsHero;
