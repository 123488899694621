import React from "react";
import Facebook from "../../../assets/images/fb-icon.png";
import Twitter from "../../../assets/images/twitter-icon.png";
import Instagram from "../../../assets/images/insta-icon.png";


const SocialLinks = () => {

    return (
        <div className="hidden lg:block">
          <ul className="flex rotate-[270deg]">
                  <li data-aos="fade-up" className="text-[#FCD462] text-sm cursor-pointer flex items-center">
                    <img className="mr-3 w-[26px]" src={Facebook} alt="fb"/>
                    Facebook
                  </li>
                  <li data-aos="fade-down" className="ml-10 text-[#FCD462] text-sm cursor-pointer flex items-center">
                    <img className="mr-3 w-[26px]" src={Twitter} alt="twitter"/>
                    Twitter
                  </li>
                  <li data-aos="fade-up" className="ml-10 text-[#FCD462] text-sm cursor-pointer flex items-center">
                    <img className="mr-3 w-[26px]" src={Instagram} alt="insta"/>
                    Instagram
                  </li>
                </ul>
        </div>
    )
};

export default SocialLinks;