import React, { useEffect } from "react";
import Right from "../../../assets/images/rightpopop.svg";
import { useState } from "react";
import ReactStars from "react-rating-stars-component";
import { Link } from "gatsby";
import { generateImageUrl } from "../../../client";
import Comment from "../comment";

const ProductRating = ({ Products, SiteController }) => {
  const [addToCartPopup, setAddToCartPopup] = useState(false);
  const [counter, setCounter] = useState(1);
  const [updatedProducts, setUpdatedProducts] = useState(
    Products[0].content[0].allProducts
  );



  // Getting Product From Local Storage product_name
  const storageProductName =
    typeof window !== "undefined" && window.localStorage.getItem("productName");

  const foundProduct = updatedProducts.find(
    (product) => product.productName === storageProductName
  );

  useEffect(() => {
    const localStorageData =
      JSON.parse(localStorage.getItem("allData")) ||
      Products[0].content[0].allProducts;

    const allProducts = localStorageData.map((product) => {
      return product.productName === foundProduct?.productName
        ? {
            ...product,
            quantity:
              product.quantity === 0 ? product.quantity + 1 : product.quantity,
          }
        : product;
    });
    setUpdatedProducts(allProducts);
  }, []);
  function updateProducts(status) {
    const newData = updatedProducts.map((product) =>
      product.productName === storageProductName
        ? {
            ...product,
            quantity:
              status === "increase"
                ? product.quantity + 1
                : product.quantity > 0
                ? product.quantity - 1
                : product.quantity,
          }
        : product
    );
    setUpdatedProducts(newData);
  }
  function addToCart() {
    localStorage.setItem("allData", JSON.stringify(updatedProducts));
  }
  const scrollTop = () => {
    window.scrollTo({ top: 100, behavior: "smooth" });
  };

  const ratingChanged = (newRating) => {};
  return (
    <>
      <div className="container px-6 lg:pl-[86px] lg:pr-[91px] mx-auto relative">
        {addToCartPopup && (
          <div className="addCartPopup flex justify-between items-center bg-[#474742] py-2 px-4 absolute top-[65px] w-[86%] md:w-[94%] lg:w-[86%]">
            <div className="flex items-center">
              <img
                className="w-[22px]"
                src={Right}
                alt="Right"
                loading="lazy"
              />
              <p className="text-white carmorant-font text-[9px] md:text-[22px] font-semibold ml-4 md:ml-8">
                <span className="text-[#FCD462]">“{storageProductName}”</span>
                has been added to your cart.
              </p>
            </div>
            <div className="hover:underline text-[#FCD462] underline-offset-1">
              <Link to="/cart">
                <p className="cursor-pointer text-[#FCD462] carmorant-font text-[9px] md:text-[22px] font-semibold">
                  View Cart
                </p>
              </Link>
            </div>
          </div>
        )}
        <div className="flex justify-between pt-[6rem] lg:pt-40 pb-24 flex-col lg:flex-row">
          <div data-aos="zoom-in-up" className="w-full lg:w-2/4">
            <div className="flex flex-col items-center mt-[15px] md:mt-0">
              {foundProduct?.productImage && (
                <img
                  className="w-[210px] md:w-[355px] mt-0 sm:mt-[48px] md:mt-[42px] lg:mt-0 lg:w-[320px] mr-0 lg:mr-[40px]"
                  alt="GoldenRing"
                  loading="lazy"
                  src={generateImageUrl(
                    foundProduct?.productImage?.asset?._ref || ""
                  )}
                />
              )}
              <div className="flex py-8 lg:py-16">
                {foundProduct?.productPictures?.map((single, index) => (
                  <img
                    key={index}
                    className="w-[16%] md:w-[20%] lg:w-[70px] mr-10"
                    src={generateImageUrl(single?.image?.asset?._ref)}
                    alt="GoldenRing"
                    loading="lazy"
                  />
                ))}
              </div>
            </div>
            <div data-aos="zoom-in-up" className="table w-full">
              <p className="py-2 carmorant-font text-[22px] text-white font-semibold">
                {foundProduct?.additionalInformationTable?.tableHeading}
              </p>
              <table className="border-solid border-[1px] border-gray-500 w-full">
                <tbody>
                  {foundProduct?.additionalInformationTable[0]?.table?.rows?.map(
                    (single, index) => (
                      <tr
                        key={index}
                        className={`cursor-pointer hover:text-[#FCD462] hover:bg-[#353530] ease-in-out duration-300 text-white border-solid border-[1px] border-gray-500 ${
                          index === 0 &&
                          "text-[#FCD462] cursor-default hover:bg-[#1c1b19]"
                        }`}
                      >
                        {single?.cells?.map((value, index) => (
                          <td
                            key={index}
                            className="py-2 px-4 border-r-[1px] border-gray-500 text-[12px]"
                          >
                            {value}
                          </td>
                        ))}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div
            data-aos="zoom-in-down"
            className="w-full lg:w-2/4 ml-0 lg:ml-20 pt-12 lg:pt-0"
          >
            <p className="carmorant-font text-3xl md:text-4xl lg:text-5xl text-white font-semibold">
              {foundProduct?.productName}
            </p>
            <div className="star flex py-4">
              <ReactStars
                count={5}
                onChange={ratingChanged}
                size={50}
                activeColor="#FCD462"
              />
            </div>
            <p
              data-aos="zoom-in-up"
              className="text-[#FCD462] font-[800] text-2xl md:text-3xl lg:text-4xl"
            >
              PKR
              {foundProduct?.productWeight?.match(/\d+/)[0] *
                SiteController[0].price}
            </p>
            <div
              data-aos="zoom-in-up"
              className="border-[1px] border-gray-500 my-8"
            ></div>

            <p
              data-aos="zoom-in-up"
              className="carmorant-font text-xl md:text-3xl lg:text-3xl text-white"
            >
              {foundProduct?.productAvailibilty?.availibility}
              <span className="mr-4 text-[#00FF19]">
                {foundProduct?.productAvailibilty?.stock}
              </span>
              {foundProduct?.productAvailibilty?.stockKeepingUnit}
              <span className="ml-2 text-[#00FF19]">
                {foundProduct?.productAvailibilty?.skuNumeric}
              </span>
            </p>
            <p
              data-aos="zoom-in-up"
              className="text-[9px] md:text-[14px] lg:text-[9px] pt-6 pb-8 leading-[20px] md:leading-[26px] lg:leading-[20px] uppercase text-white"
            >
              {foundProduct?.productDes}
            </p>

            <div data-aos="zoom-in-up" className="material">
              <p className="carmorant-font text-2xl md:text-3xl lg:text-3xl text-white">
                Material
              </p>
              <div className="pt-4 pb-6 md:pb-12 lg:pb-0 lg:mb-[25px] flex justify-between lg:justify-start">
                {Products[0].content[0].productMaterial?.map(
                  (single, index) => (
                    <button
                      key={index}
                      className="bg-[#656561] py-2 px-6 md:px-14 lg:px-6 text-white text-[10px] md:text-[18px] lg:text-[12px] mr-2 hover:text-[#FCD462] ease-in-out duration-300"
                    >
                      {single?.productName}
                    </button>
                  )
                )}
              </div>
              <div className="flex items-center justify-center lg:justify-start">
                <span className="flex text-white bg-[#656561]">
                  <button
                    className="py-2 md:py-4 lg:py-4 px-4 bg-[#656561] hover:bg-[#FCD462] text-2xl hover:border-transparent ease-in-out duration-300"
                    onClick={() => {
                      setCounter(counter ? counter - 1 : 0);
                      updateProducts("derease");
                    }}
                  >
                    -
                  </button>
                  <p className="text-center py-3 md:py-4 lg:py-4 text-xl font-bold w-[52px] md:w-[95px] lg:w-[80px]">
                    {foundProduct?.quantity}
                  </p>
                  <button
                    className="py-2 md:py-4 lg:py-4 px-4 hover:bg-[#FCD462] text-2xl hover:border-transparent ease-in-out duration-300"
                    onClick={() => {
                      updateProducts("increase");
                    }}
                  >
                    +
                  </button>
                </span>
                <button
                  onClick={() => {
                    setAddToCartPopup(true);
                    scrollTop();
                    addToCart();
                  }}
                  className="text-white py-[15px] px-9 border-2 hover:bg-[#FCD462] text-[12px] md:text-xl lg:text-xl hover:border-transparent ease-in-out duration-300 ml-4"
                >
                  Add to Cart
                </button>
              </div>

              <p className=" text-white py-[15px] px-4 border-2 hover:bg-[#FCD462] text-[12px] md:text-xl lg:text-xl hover:border-transparent ease-in-out duration-300 mt-4">
                Adding Making Price Per Gram In Total Price :
                {SiteController[0].makingPrice * foundProduct?.quantity}
              </p>

              <button className="text-white py-[15px] px-9 border-2 hover:bg-[#FCD462] text-[12px] md:text-xl lg:text-xl hover:border-transparent ease-in-out duration-300 mt-4">
                Total Price:{" "}
                {parseFloat(
                  foundProduct?.productWeight?.match(/\d+/)[0] *
                    SiteController[0].price
                ) *
                  foundProduct?.quantity +
                  SiteController[0].makingPrice * foundProduct?.quantity}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Comment />
    </>
  );
};
export default ProductRating;
