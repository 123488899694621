import React from "react";
import { generateImageUrl } from "../../../client";
const AboutUs = ({ section }) => {
  return (
    <div className="aboutUs-bg">
      <div className="container px-6 lg:pl-[86px] lg:pr-[91px] mx-auto flex flex-col flex-col-reverse lg:flex-row mb-[75px]">
        <div className="w-full lg:w-1/2 relative bottom-[35px]">
          {section?.craftingCards?.map((value, index) => (
            <div
              className={`flex mb-6 ${
                index % 2 ? "flex-row-reverse ml-0 justify-end" : "flex-row"
              }`}
              key={index}
            >
              <div
                data-aos="fade-down"
                className={`bg-[#353530] min-h-0 lg:min-h-[215px] xl:min-h-[280px] relative text-white px-[6px] lg:px-[14px] xl:px-[32px] w-[46%] md:w-[48%] lg:w-[45%] flex flex-col items-center ${
                  index % 2 ? "top-[34px] py-4" : "py-4 md:py-5 lg:py-6 xl:py-10"
                }`}
              >
                <img
                  className="w-[36px] md:w-[100px] lg:w-[45px] xl:w-[60px]"
                  src={generateImageUrl(value?.productimage?.asset?._ref)}
                  alt="ringing"
                  loading="lazy"
                />
                <p className="text-[14px] md:text-4xl lg:text-xl xl:text-3xl carmorant-font py-2 xl:py-4 text-center">
                  {value?.productTitle}
                </p>
                <p className="text-white text-center uppercase text-[7px] md:text-[18px] lg:text-[9px] font-light">
                  {value?.productDescription}
                </p>
              </div>

              <div
                data-aos="zoom-in-up"
                className={`bg-[#353530] min-h-0 lg:min-h-[215px] xl:min-h-[280px] relative text-white w-[46%] md:w-[48%] lg:w-[45%] flex flex-col items-center"
              ${index % 2 ? "ml-0 mr-6" : "ml-[25px] top-[28px]"}`}
              >
         
                <img
                  className="w-full"
                  src={generateImageUrl(value?.cardimage?.asset?._ref)}
                  alt="cardimage"
                  loading="lazy"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="about-us text-white w-full lg:w-[52%] pl-0 lg:pl-[51px] pb-[65px] lg:pb-0">
          <p className="uppercase pb-2 mt-8 text-[10px] md:text-xl lg:text-[16px]">
            {section?.title}
          </p>
          <p className="leading-[3rem] md:leading-[150px] lg:leading-[7rem] carmorant-font text-4xl md:text-[8rem] lg:text-[88px] pr-[36px] font-semibold">
            {section?.mainHeading}
          </p>
          <p className="text-[10px] md:text-[14px] lg:text-[10px] font-light uppercase">
            {section?.description}
          </p>
          <div className="pt-8">
            <button className="py-3 text-white hover:text-black lg:py-4 px-8 lg:px-10 border-2 hover:bg-[#FCD462] text-[16px] md:text-[30px] lg:text-xl hover:border-transparent ease-in-out duration-300">
              {section?.button?.buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
