import React from "react";
import SocialLinks from "../socialLinks";
import PopUp from "../homePageModel";
import { generateImageUrl } from "../../../client";

const HeroSection = ({ section }) => {
  return (
    <div className="relative ">
      <PopUp />
      <div className="realtive flex flex-col lg:flex-row justify-between container mx-auto px-6 lg:pl-[86px] lg:pr-[91px] lg:pt-0 pt-10 lg:pt-16 pb-24">
        <div
          data-aos="fade-down"
          className="text-white pt-[44px] w-full lg:w-[74%]"
        >
          <p className="uppercase tracking-wider lg:tracking-wide py-4 text-[10px] md:text-xl lg:text-[15px]">
            {section?.title}
          </p>
          <p className="carmorant-font text-[42px] md:text-9xl lg:text-8xl font-semibold leading-[60px] md:leading-[150px] lg:leading-[110px]">
            {section?.mainHeading}
          </p>
          <div className="pt-6 pb-4 lg:pb-0 lg:pt-20 flex justify-center lg:justify-start">
            <button
              data-aos="fade-right"
              className="buy-button text-white hover:text-black w-[50%] lg:w-[30%] py-4 lg:py-2 px-6 lg:px-10 border-2 hover:bg-[#FCD462] text-[16px] md:text-[30px] lg:text-xl hover:border-transparent ease-in-out duration-300"
            >
              {section?.button?.buyButton}
            </button>
            <button
              data-aos="fade-left"
              className="text-white hover:text-black w-[50%] lg:w-[30%]  py-4 lg:py-2 px-4 lg:px-8 border-2 hover:bg-[#FCD462] text-[16px] md:text-[30px] lg:text-xl hover:border-transparent ease-in-out duration-300 ml-4"
            >
              {section?.button?.button?.exploreMore}
            </button>
          </div>
        </div>
        <div
          data-aos="zoom-in"
          className="w-[100%] lg:w-[600px] h-[300px] md:h-[675px] lg:h-[520px]"

          style={{
            backgroundImage: `url(${
              generateImageUrl(section?.image?.asset?._ref) || ""
            })`,
            backgroundSize: `cover`,
            backgroundRepeat: `no-repeat`,
            backgroundPosition: `center`,
            loading: `lazy`,
          }}
        ></div>
      </div>
      <div className="absolute -left-[138px] top-[390px]">
        <SocialLinks />
      </div>
    </div>
  );
};

export default HeroSection;
